const restoreFocusWhenDisplayedGoogleOneTap = (event: CustomEvent<boolean>): void => {
    const displayed = event.detail;

    if (!displayed) {
        return;
    }

    const previousFocusedElement = document.activeElement as HTMLElement | undefined;

    previousFocusedElement?.addEventListener('blur', () => setTimeout(() => previousFocusedElement.focus(), 0), {
        once: true,
    });
};

window.addEventListener('googleOneTapDisplayed', restoreFocusWhenDisplayedGoogleOneTap);
