import { q, qq, ww } from 'bobjoll/ts/library/dom';
import { ResourceConfig } from 'CommonApp/resource/resource.vm';
import { capitalize } from 'Library/helpers/text';
import { handleSearchOnBar, lauchSponsorPopup, sendStatsSearch } from 'Partials/search/search.helpers';
import { getUrlParam } from 'Project/ts/common/urls.helper';
import NewFilters from 'Project/ts/pods/filters/filters';
import User from 'Project/ts/pods/user/main';

export class SearchBar {
    private static headerHeight: number = 54;
    public static searchHolderELement = '.search-holder';
    public static searchType: SearchType;
    public static searchForm: HTMLFormElement | null = document.querySelector('.search-holder form.home_search_input')
        ? document.querySelector('.search-holder form.home_search_input')
        : document.querySelector('.search-holder form.main_search_bar');

    private static instance: SearchBar;
    private static radioSearchAnimatedIcon = q('#radio_search_by_animated_icons') as HTMLInputElement;
    private static radioSearchUicon = q('#radio_search_by_uicons') as HTMLInputElement;
    public static searchTypes: SearchType[] = [
        {
            type: 'icon',
            name: capitalize(messages.common.icons),
            placeholder: messages.search.placeholder_icons,
            url: messages.url.search,
            default: true,
            resourceConfig: 'standard',
        },
        {
            type: 'icon-packs',
            name: capitalize(messages.common.icon_packs),
            placeholder: messages.search.placeholder_icon_packs,
            group: 'icon',
            url: `/${messages.url.packs_search}`,
            resourceConfig: 'standard',
        },
        {
            type: 'icon-styles',
            name: capitalize(messages.common.icon_styles),
            placeholder: messages.search.placeholder_icon_styles,
            group: 'icon',
            url: `/${messages.url.style_search}`,
            resourceConfig: 'standard',
        },
        {
            type: 'sticker',
            name: capitalize(messages.common.stickers),
            placeholder: messages.search.placeholder_stickers,
            url: messages.url.search,
            resourceConfig: 'sticker',
        },
        {
            type: 'sticker-packs',
            name: capitalize(messages.common.sticker_packs),
            placeholder: messages.search.placeholder_sticker_packs,
            group: 'sticker',
            url: `/${messages.url.packs_search}`,
            resourceConfig: 'sticker',
        },
        {
            type: 'sticker-styles',
            name: capitalize(messages.common.sticker_styles),
            placeholder: messages.search.placeholder_sticker_styles,
            group: 'sticker',
            url: `/${messages.url.style_search}`,
            resourceConfig: 'sticker',
        },
        {
            type: 'standard-packs',
            name: capitalize(messages.common.icon_packs),
            placeholder: messages.search.placeholder_icon_packs,
            group: 'icon',
            url: `/${messages.url.packs_search}`,
            resourceConfig: 'standard',
        },
        {
            type: 'animated-icon',
            name: capitalize(messages.common.animated_icons),
            placeholder: messages.search.placeholder_animated_icons,
            url: messages.url.search,
            resourceConfig: 'animated-icon',
        },
        {
            type: 'uicon',
            name: capitalize(messages.common.interface_icons),
            placeholder: messages.search.placeholder_interface_icons,
            url: messages.url.search,
            resourceConfig: 'uicon',
        },
    ];

    constructor() {
        if (!SearchBar.instance) {
            SearchBar.instance = this;
            SearchBar.init();
        }
    }

    private static init() {
        SearchBar.addEventListeners();
        SearchBar.cleanSearch();
        SearchBar.cleanLanguageUrl();

        if (q('.hero--home')) {
            this.headerHeight = 250;
        }

        if (!ww.Resource.resourceConfig) {
            ww.Resource.setResourceConfig(ww.RESOURCE_TYPE);
        }
        ww.Resource.resourceConfig && this.handleGroupBypack(ww.Resource.resourceConfig);

        ww.Resource.resourceConfig && this.handleGroupByStyle(ww.Resource.resourceConfig);
    }

    private static isGroupByPacks(): boolean {
        const switchPackElementHome = document.querySelector('#switch_search_by_packs_home') as HTMLInputElement;
        const switchPackElement = document.querySelector('#switch_search_by_packs') as HTMLInputElement;
        const switchButtonTarget = switchPackElementHome ? switchPackElementHome : switchPackElement;

        return switchButtonTarget?.checked;
    }

    private static isGroupByStyles(): boolean {
        const switchStyleElementHome = document.querySelector('#switch_search_by_style_home') as HTMLInputElement;
        const switchStyleElement = document.querySelector('#switch_search_by_style') as HTMLInputElement;
        const switchButtonTargetStyle = switchStyleElementHome ? switchStyleElementHome : switchStyleElement;

        return switchButtonTargetStyle?.checked;
    }

    private static isNotGroupableType(): boolean {
        const isAnimatedIconSelected = this.radioSearchAnimatedIcon ? this.radioSearchAnimatedIcon.checked : false;
        const isInterfaceIconSelected = this.radioSearchUicon ? this.radioSearchUicon.checked : false;

        return isAnimatedIconSelected || isInterfaceIconSelected;
    }

    private static checkGroupBy(): GroupBy {
        if (this.isNotGroupableType()) {
            return {
                packs: false,
                styles: false,
            };
        }

        return {
            packs: this.isGroupByPacks(),
            styles: this.isGroupByStyles(),
        };
    }

    private static getTypeOfSearch(stringType: string, groupBy: GroupBy): SearchType | undefined {
        if (groupBy.packs || groupBy.styles) {
            const groupId = groupBy.packs ? 'packs' : 'styles';

            const groupedType = SearchBar.searchTypes.find(
                searchType => searchType.type === (groupBy ? `${stringType}-${groupId}` : stringType),
            );

            if (groupedType) {
                return groupedType;
            }
        }

        return SearchBar.searchTypes.find(searchType => searchType.type === stringType);
    }

    private static setWithTypeOfSearch(
        stringType: string,
        groupBy: GroupBy,
        typeOfSearchInputElement?: HTMLInputElement,
    ) {
        const searchType = this.getTypeOfSearch(stringType, groupBy);
        const searchForm: HTMLFormElement | null = document.querySelector('.search-holder form.home_search_input')
            ? document.querySelector('.search-holder form.home_search_input')
            : document.querySelector('.search-holder form.main_search_bar');
        const searchSelected: HTMLSpanElement | null = searchForm && searchForm?.querySelector('.search-selected span');

        ww.Resource && ww.Resource.setResourceConfig(searchType?.resourceConfig);
        ww.Resource && ww.Resource.resourceConfig && this.handleGroupBypack(ww.Resource.resourceConfig);

        ww.Resource.resourceConfig && this.handleGroupByStyle(ww.Resource.resourceConfig);

        if (searchType) {
            localStorage.setItem('search-type', searchType.type);

            if (searchForm) {
                searchForm.action = APP_URL + searchType.url.replace('/', '');
            }

            if (searchSelected) {
                searchSelected.innerHTML = capitalize(searchType.name);
            }

            if (searchForm) {
                const searchInputElement = searchForm.querySelector('input[type=search]') as HTMLInputElement;
                searchInputElement.setAttribute('placeholder', searchType.placeholder);

                if (typeOfSearchInputElement) {
                    searchInputElement.setAttribute('placeholder', searchType.placeholder);
                }
            }
        }
        if (!ww.FeatureFlag.isEnabled('filter_group_by')) {
            SearchBar.SetLabelForAllResources(ww.Resource.resourceConfig.search.labelForAllResources);
        }
    }

    private static selectTargetForm(form: HTMLFormElement | null) {
        if (form) {
            SearchBar.resetSearchFormFilters();

            !SearchBar.isEmptySearch(form)
                ? SearchBar.search()
                : User.alert('notification-search', messages.search.minimal_characters, 'warning', 'bottom-left', 4500);
        }
    }

    private static async addEventListeners() {
        const preventRedirectAlert = (e: Event) => {
            e.preventDefault();
            User.alert('notification-search', messages.search.minimal_characters, 'warning', 'bottom-left', 4500);
        };

        document.querySelectorAll('.search-holder form:not(.author_search)').forEach((searchForm: HTMLFormElement) => {
            searchForm.addEventListener('submit', function(e: Event) {
                const currentInputSearch: HTMLInputElement | null = this.querySelector('input[type=search][name=word]');
                if (currentInputSearch && currentInputSearch.value.length < 1) {
                    preventRedirectAlert(e);
                } else {
                    try {
                        lauchSponsorPopup();
                    } catch (error) {
                        console.error(error);
                    }
                    sendStatsSearch(currentInputSearch?.value || '');
                    SearchBar.search(e);
                }
            });
        });

        qq('input[type=radio][name=type]').forEach((element: HTMLInputElement) => {
            element.addEventListener('change', e => {
                const typeSearchElementChanged = e.target as HTMLInputElement;
                const formFilters = document.getElementById('form_filters');
                const formFiltersInputChecked: HTMLInputElement | null =
                    formFilters && formFilters.querySelector('#form_filters input:checked');
                const formFiltersInputTarget: HTMLInputElement | null =
                    formFilters && formFilters.querySelector(`input[data-type=${typeSearchElementChanged.value}]`);

                if (typeSearchElementChanged) {
                    this.checkGroupBy();
                    SearchBar.setWithTypeOfSearch(typeSearchElementChanged.value, this.checkGroupBy(), element);
                    const form = typeSearchElementChanged.form;
                    this.selectTargetForm(form);

                    ww.Resource.setResourceConfig(typeSearchElementChanged.value);
                    ww.Resource.resourceConfig && this.handleGroupBypack(ww.Resource.resourceConfig);
                    ww.Resource.resourceConfig && this.handleGroupByStyle(ww.Resource.resourceConfig);

                    if (formFiltersInputChecked) {
                        formFiltersInputChecked.checked = false;
                    }

                    if (formFiltersInputTarget) {
                        formFiltersInputTarget.checked = true;
                    }

                    if (form && !SearchBar.isEmptySearch(form)) {
                        handleSearchOnBar();
                    }
                }
            });
        });

        const searchGroupInputs = qq('.search-holder input[type=radio][name=search-group]');

        searchGroupInputs?.forEach(element => {
            element.addEventListener('change', e => {
                let groupSearchElementChanged = e.target as HTMLInputElement;
                if (groupSearchElementChanged) {
                    qq('input[type=radio][name=type]').forEach((element: HTMLInputElement) => {
                        if (element.checked == true) {
                            this.checkGroupBy();
                            NewFilters.handleViewAsFilter();
                            SearchBar.setWithTypeOfSearch(element.value, this.checkGroupBy(), element);
                            let form = groupSearchElementChanged.form;
                            if (form) {
                                SearchBar.isEmptySearch(form)
                                    ? User.alert(
                                          'notification-search',
                                          messages.search.minimal_characters,
                                          'warning',
                                          'bottom-left',
                                          4500,
                                      )
                                    : SearchBar.search(e);
                            }
                        }
                    });
                }
            });
        });

        q('.tabs-component input[type=checkbox][name=search-group]')?.addEventListener('change', () => {
            const searchByAllInput = document.getElementById('switch_search_by_all') as HTMLInputElement;

            if (searchByAllInput?.checked) {
                q('.search-holder input#switch_search_by_packs')?.click();
            } else {
                q('.search-holder input#switch_search_by_all')?.click();
            }
        });

        qq('#header input[type=search][name=word], #hero input[type=search][name=word]').forEach(element => {
            element.addEventListener('focus', e => {
                let element = e.target as HTMLElement;
                if (element) {
                    let elementToChangeStyle = element.closest('.fake-search');
                    if (elementToChangeStyle) {
                        elementToChangeStyle.classList.add('focus');
                    }
                }
            });
            element.addEventListener('focusout', e => {
                let element = e.target as HTMLElement;
                if (element) {
                    let elementToChangeStyle = element.closest('.fake-search');
                    if (elementToChangeStyle) {
                        elementToChangeStyle.classList.remove('focus');
                    }
                }
            });
        });
    }

    private static search(e?: Event) {
        e && e.preventDefault();
        const searchForm: HTMLFormElement | null = document.querySelector('.search-holder form.home_search_input')
            ? document.querySelector('.search-holder form.home_search_input')
            : document.querySelector('.search-holder form.main_search_bar');

        if (searchForm) {
            handleSearchOnBar();
        }
    }

    private static cleanSearch() {
        const searchForm: HTMLFormElement | null = document.querySelector('.home_search_input')
            ? document.querySelector('.home_search_input')
            : document.querySelector('.search-holder form.main_search_bar');

        if (searchForm) {
            const cleanSearchButton = searchForm.querySelector('.clean-search__button') as HTMLButtonElement;
            const searchInputElement = searchForm.querySelector('input[type=search]') as HTMLInputElement;

            if (searchInputElement.value != '') {
                cleanSearchButton.classList.add('show-btn');
            }

            searchInputElement.addEventListener('input', () => {
                if (searchInputElement.value != '') {
                    cleanSearchButton.classList.add('show-btn');
                } else {
                    cleanSearchButton.classList.remove('show-btn');
                }
            });

            cleanSearchButton.addEventListener('click', () => {
                searchInputElement.value = '';
                searchInputElement.focus();
                cleanSearchButton.classList.remove('show-btn');
            });
        }
    }

    private static cleanLanguageUrl() {
        const locationPathName = window.location.pathname;
        const locationSearch = window.location.search;

        if (getUrlParam('ol')) {
            let urlParams = new URLSearchParams(locationSearch);
            urlParams.delete('ol');
            let urlSearchParam = urlParams.toString();
            let cleanUrl = locationPathName + '?' + urlSearchParam;

            window.history.pushState('', document.title, cleanUrl);
        }
    }

    private static isEmptySearch(form: HTMLFormElement) {
        const inputWordSearch: HTMLInputElement | null = form.querySelector('input[type=search][name=word]');
        return inputWordSearch ? inputWordSearch.value.length === 0 : false;
    }

    private static resetSearchFormFilters() {
        const searchForm: HTMLFormElement | null = document.querySelector('.search-holder form.home_search_input')
            ? document.querySelector('.search-holder form.home_search_input')
            : document.querySelector('.search-holder form.main_search_bar');

        const hiddenFormElements = searchForm?.querySelectorAll('input[type="hidden"][id*="main-search"]');
        hiddenFormElements?.forEach((element: HTMLInputElement) => {
            element.value = '';
        });
    }

    public static SetLabelForAllResources = (text: string): void => {
        document
            .querySelectorAll('label[for="switch_search_by_all"]:not(.radio)')
            .forEach(label => (label.innerHTML = text));
    };

    public static handleGroupBypack = (resourceConfig: ResourceConfig) => {
        const groupByPackHome: HTMLElement | null = q('section#hero div.stickers ul li.radio-group-by-pack');
        const groupByPack: HTMLElement | null = q('div.stickers ul li.radio-group-by-pack');

        const switchPackElementHome: HTMLInputElement | null = document.querySelector('#switch_search_by_packs_home');
        const switchPackElement: HTMLInputElement | null = document.querySelector('#switch_search_by_packs');
        const groupByPackTarget: HTMLElement | null = groupByPackHome ? groupByPackHome : groupByPack;
        const switchButtonTarget: HTMLInputElement | null = switchPackElementHome
            ? switchPackElementHome
            : switchPackElement;

        if (resourceConfig && !resourceConfig.search.groupBypack) {
            groupByPackTarget?.classList.add('disabled');

            if (switchButtonTarget) {
                switchButtonTarget.checked = false;
            }
        }

        if (resourceConfig.search.groupBypack) {
            groupByPackTarget?.classList.remove('disabled');
        }
    };
    public static handleGroupByStyle = (resourceConfig: ResourceConfig) => {
        const groupByStyleHome = q('section#hero div.stickers ul li.radio-group-by-style');
        const groupByStyle = q('div.stickers ul li.radio-group-by-style');

        const switchStyleElementHome: HTMLInputElement | null = document.querySelector('#switch_search_by_style_home');
        const switchStyleElement: HTMLInputElement | null = document.querySelector('#switch_search_by_style');
        const groupByStyleTarget: HTMLElement | null = groupByStyleHome ? groupByStyleHome : groupByStyle;
        const switchButtonTarget: HTMLInputElement | null = switchStyleElementHome
            ? switchStyleElementHome
            : switchStyleElement;

        if (resourceConfig && !resourceConfig.search.groupByStyle) {
            groupByStyleTarget?.classList.add('disabled');

            if (switchButtonTarget) {
                switchButtonTarget.checked = false;
            }
        }

        if (resourceConfig.search.groupByStyle) {
            groupByStyleTarget?.classList.remove('disabled');
        }
    };
}

const searchBar = new SearchBar();
export default searchBar;

interface SearchType {
    type:
        | 'icon'
        | 'icons'
        | 'packs'
        | 'icon-packs'
        | 'icon-styles'
        | 'sticker'
        | 'sticker-packs'
        | 'sticker-styles'
        | 'standard-packs'
        | 'animated-icon'
        | 'uicon';
    group?: 'icon' | 'sticker' | 'animated-icon' | 'uicon';
    name: string;
    placeholder: string;
    url: string;
    default?: true;
    resourceConfig: string;
}

interface PlaceholderString {
    [key: string]: string;
}

interface GroupBy {
    packs: boolean;
    styles: boolean;
}
