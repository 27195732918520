import { cookie } from 'Library/cookie';
import { getGlobalVariables } from 'Library/helpers/global';
import { KEventShow } from 'BobjollNotify';
import { q } from 'bobjoll/ts/library/dom';
import Countdown from 'bobjoll/ts/partials/countdown-v1.0';
import notify from 'Partials/notify';

const Templar = {
    freepik: require('Project/ts/templates/banner/crusades/freepik.hbs'),
    flaticon: require('Project/ts/templates/banner/crusades/flaticon.hbs'),
};

const eventHandlerNotifyShow = function(e: KEventShow) {
    const settings = e.extra;

    if (!settings.id.match(/flaticon|freepik/)) return;

    const crusade = document.getElementById(settings.id);

    let crusadeName = settings.id.replace('crusade', '');

    if ('-freepik' === crusadeName) {
        crusadeName = '';
    }

    if ('-flaticon' === crusadeName) {
        crusadeName = '';
    }

    if (crusade) {
        const notifyAction = q('a', crusade);
        const notifyClose = q('.notification__close', crusade);

        ga('send', 'event', 'campaign', 'onload', `banner-welcome${crusadeName}`);

        if (notifyAction) {
            notifyAction.addEventListener('click', () => ga('send', 'event', 'campaign', 'click', `banner-welcome-gopremium${crusadeName}`));
        }

        if (notifyClose) {
            notifyClose.addEventListener('click', () => ga('send', 'event', 'campaign', 'click', `banner-welcome-close${crusadeName}`));
        }

        if (settings.schedule) {
            const countdown = q('.countdown', crusade);

            if (countdown) {
                new Countdown({
                    container: countdown,
                    dateEnd: settings.schedule.dateEnd,
                });
            }
        }
    }
};

export const crusadeCallback = async function() {
    const crusadeCookie = cookie.getItem('gr_welcome');

    if (crusadeCookie) {
        const crusadeCookieData: CrusadeCookieData = JSON.parse(decodeURIComponent(crusadeCookie));
        const crusadeDateStart = new Date(crusadeCookieData.date);
        const crusadeDateEnd = new Date(crusadeDateStart.getTime() + 24 * 60 * 60 * 1000);
        const crusadeTemplate = Templar[crusadeCookieData.site];

        notify.addEventListener('show', eventHandlerNotifyShow);
        notify.addBanner({
            id: `crusade-${crusadeCookieData.site}`,
            class: 'crusades',
            html: crusadeTemplate(getGlobalVariables()),
            priority: 1,
            position: 'bottom',
            showPremium: true,
            showMobile: true,
            schedule: {
                dateStart: crusadeDateStart,
                dateEnd: crusadeDateEnd,
                dateExpire: new Date(new Date().getTime() + 30 * 60 * 1000),
            },
        });
    }
};

interface CrusadeCookieData {
    site: 'flaticon' | 'freepik';
    date: string;
}
