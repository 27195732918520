import { delegate } from 'bobjoll/ts/library/dom';
import { EventTracker } from 'Library/eventTacker';

['click', 'auxclick'].forEach((event: string) =>
    delegate('.track', event, function(this: HTMLLinkElement, e: Event) {
        EventTracker.getArgsFromElementAndSend(this);
    }),
);

delegate('.icon-options li:not(.GA_CM_view-details) button', 'click', function(this: HTMLLinkElement, e: Event) {
    const link = this.dataset.src;
    if (link) {
        e.preventDefault();
        document.location.href = link;
    }
});

['click', 'auxclick'].forEach((event: string) =>
    delegate('.track-ga4', event, function(this: HTMLLinkElement, e: Event) {
        EventTracker.getArgsFromElementGa4AndSend(this);
    }),
);
