var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container-fluid\">\n    <div class=\"row row--vertical-center row--horizontal-center\">\n        <div class=\"crusades__discount\">"
    + alias3((lookupProperty(helpers,"language")||(depth0 && lookupProperty(depth0,"language"))||alias2).call(alias1,"%s OFF","30%",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":69}}}))
    + "</div>\n        <div class=\"crusades__description\">"
    + ((stack1 = (lookupProperty(helpers,"language")||(depth0 && lookupProperty(depth0,"language"))||alias2).call(alias1,"Your %s Premium subscription comes with a %s30%% discount on %s%s","Flaticon","<b>","Freepik","</b>",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":4,"column":43},"end":{"line":4,"column":161}}})) != null ? stack1 : "")
    + "</div>\n        <div class=\"crusades__schedule\">\n            <div class=\"crusades__countdown countdown\"></div>\n            <p class=\"crusades__countdown__description\">"
    + alias3((lookupProperty(helpers,"language")||(depth0 && lookupProperty(depth0,"language"))||alias2).call(alias1,"*valid 24 hours",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":7,"column":56},"end":{"line":7,"column":88}}}))
    + "</p>\n        </div>\n        <a href=\"https://www.freepik.com/profile/premium?coupon=welcome&utm_source=freepik&utm_medium=banner&utm_campaign=welcome-successful-payment\" class=\"button button--md button--flat button--premium\" target=\"_blank\">"
    + alias3((lookupProperty(helpers,"language")||(depth0 && lookupProperty(depth0,"language"))||alias2).call(alias1,"Go Premium",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":9,"column":221},"end":{"line":9,"column":248}}}))
    + "</a>\n    </div>\n</div>";
},"useData":true});