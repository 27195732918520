import 'UI/notifications';
import 'Project/ts/pages/all';
import 'Partials/history';
import 'BobjollTrigger';
import 'BobjollScroll';
import 'Variables';
import 'Library/googleOneTap';
import 'Project/ts/partials/Premium';

import { ww } from 'bobjoll/ts/library/dom';
import accordion from 'BobjollAccordion';
import { Modal, modal } from 'BobjollModal';
import { printSalesforcePixel } from 'CommonApp/pixels/salesforce';
import { SponsorBanner } from 'CommonApp/sponsor/sponsor-banner';
import { handleAnchorLanguage } from 'CommonFI/element';
import { enableReloadAsyncPage } from 'CommonFI/reloadAsyncPage.helpers';
import { EventTracker } from 'Library/eventTacker';
import { avoidEvents } from 'Library/helpers/avoidEvents';
import Scroll from 'Library/scroll';
import { share } from 'Library/share';
import { AnimationScroll } from 'Partials/animation';
import searchBar from 'Partials/search/searchBar';
import { copyApiKey, copyToClipboardHandleListeners, copyUrlShare } from 'Pods/copyPaste/copyToClipboard';
import { callHotjarTrigger } from 'Project/ts/common/hotjar.helpers';
import { Detail } from 'Project/ts/pages/icon-detail/detailService';
import { LazyModal } from 'Project/ts/pods/modals/lazyModal';
import User from 'Project/ts/pods/user/main';

import { Attribution } from './partials/attributionService';
import Lazyload from './ui/lazyload';

import { Buffer } from 'buffer';

const urlShare = document.querySelector('input.input-share-url') as HTMLInputElement;

ww.AnimationScroll = AnimationScroll;
ww.SponsorBanner = SponsorBanner;
ww.EventTracker = EventTracker;
ww.Modal = Modal;
ww.modal = modal;
ww.Detail = Detail;
ww.share = share;
ww.Attribution = Attribution;
ww.accordion = accordion;
ww.searchBar = searchBar;

ww.Buffer = Buffer;

setTimeout(() => Lazyload.run(), 50);

enableReloadAsyncPage();

/**
 * SVG Fallback
 */
if (!!(window as any).MSInputMethodContext && !!(document as any).documentMode) {
    var svg4everybody = require('bobjoll/ts/library/svg4everybody.legacy.js');

    svg4everybody({
        polyfill: true,
    });
}

/**
 * Lazyload on scroll
 */
Scroll.add(() => Lazyload.run());

if (ESSENTIALS_STATUS) {
    import('CommonApp/essentials/essentials').then(module => {
        ww.Essentials = module.Essentials;
        ww.Essentials.init();
    });
}

copyToClipboardHandleListeners();

printSalesforcePixel();

LazyModal.callModalPremium();
LazyModal.callModalLicense();
LazyModal.callModalAttribution();

window.addEventListener('userSessionLoaded', () => {
    if (ww.view.section === 'pack-search') {
        handleAnchorLanguage();
    }
});

copyApiKey();

if (urlShare) {
    urlShare.addEventListener('click', e => {
        let inputShareTarget = e.target as HTMLInputElement;
        copyUrlShare(inputShareTarget);
    });
}

if (User.isPremium()) {
    callHotjarTrigger('premium-user');
}

const eventsToAvoidOnImages = ['contextmenu', 'dragstart'];
const imageTagTypes = ['IMG', 'PICTURE', 'SVG'];
const parentElementSelector = '#detail';
avoidEvents(eventsToAvoidOnImages, imageTagTypes, parentElementSelector);
