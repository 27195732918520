export const avoidEvents = (events: string[], elements: string[], parentElementSelector: string) => {
    events.forEach((eventType: string) =>
        document.addEventListener(eventType, event => {
            const eventTarget = event.target as HTMLElement;
            const parentElement = parentElementSelector && document.querySelector(parentElementSelector);
            if (elements.includes(eventTarget.tagName) && parentElement) {
                event.preventDefault();
            }
        }),
    );
};
