import { q, qq, ww } from 'bobjoll/ts/library/dom';
import { cookie } from 'Library/cookie';
import { EventTracker } from 'Library/eventTacker';

import { crusadeCallback } from './crusades';

/**
 * Crusades
 */
crusadeCallback();

/**
 * Adobe Coupon
 */
let couponElement = q('#layer_coupon');
if (couponElement) {
    ww.notify.addCustom({
        // disableOnScheduledPriorities: [3, 4],
        id: 'sponsor-coupon',
        priority: 5,
        showMobile: false,
        showPremiumFlaticon: false,
        expires: () => new Date(new Date().getTime() + 48 * 60 * 60 * 1000),
        callback: () => {
            EventTracker.printGA({
                type: 'imp',
                section: 'stockbanner',
            });
            qq('#layer_coupon').forEach(coupon => {
                q('#layer_coupon .coupon__holder')?.addEventListener('mouseup', () => {
                    EventTracker.printGA({
                        type: 'clk',
                        section: 'stockbanner',
                    });
                });
                qq('a[onclick*="layer_coupon"]', coupon).forEach(link => {
                    link.addEventListener('click', () => {
                        ww.notify.hide('sponsor-coupon');
                    });
                });
            });
        },
        closeCallback: () => {},
    });
}

/**
 * Billing Info
 */
if (UPDATE_BILLING_INFO) {
    let updateBillingInfoElement = document.getElementById('update-billing-info');
    let closeButton = updateBillingInfoElement?.querySelector('.close');
    if (closeButton) {
        closeButton.addEventListener('click', () => {
            updateBillingInfoElement?.remove();
            document.body.classList.remove('topbar-active');
            const expiredCookie = new Date().getTime() + 7 * 24 * 60 * 60 * 1000; // 7 days
            cookie.setItem('update-billing-info', '1', {
                expires: new Date(expiredCookie),
            });
        });
    }
}

/**
 * Follow Info Popup Guest
 */
/*notify.addCustom({
    id: 'info-follow-popup-guest',
    priority: 6,
    showGuestOnly: true,
    showMobile: false,
    expires: () => new Date(new Date().getTime() + 3600000 * 24 * 7), //a week
    callback: () => {
        let followPopupTemplate = require('Project/ts/templates/parts/follow-modal.hbs');
        let footer = q('#footer');
        if (null !== footer) {
            footer.insertAdjacentHTML('afterend', followPopupTemplate({ isUser: false, LANGUAGE_SHORT: LANGUAGE_SHORT }));
            let followElement = q('#follow-popup') as HTMLInputElement;
            if (null != followElement) {
                qq('.btn-close', followElement).forEach(link => {
                    link.addEventListener('click', () => {
                        followElement.classList.add('hide');
                        notify.hide('info-follow-popup-guest');
                    });
                });
                qq('a.btn', followElement).forEach(link => {
                    link.addEventListener('click', () => {
                        notify.hide('info-follow-popup-guest');
                    });
                });
            }
        }
        ga('send', 'event', 'feature-follow', 'onload-popup', '', {
            nonInteraction: true,
        });
    },
    closeCallback: () => {},
});*/

/**
 * Follow Info Popup Registered
 */
/*notify.addCustom({
    id: 'info-follow-popup-registered',
    priority: 6,
    showGuestOnly: false,
    showMobile: false,
    expires: () => new Date(new Date().getTime() + 3600000 * 24 * 30), //a month
    callback: () => {
        let followsAuthor = [];
        try {
            followsAuthor = JSON.parse(localStorage.getItem('fi_' + User.id() + '_follows') as string).author;
        } catch (e) {
            //do nothing
        }

        if (followsAuthor.length == 0) {
            let followPopupTemplate = require('Project/ts/templates/parts/follow-modal.hbs');
            let footer = q('#footer');
            if (null !== footer) {
                footer.insertAdjacentHTML('afterend', followPopupTemplate({ isUser: true, LANGUAGE_SHORT: LANGUAGE_SHORT }));
                let followElement = q('#follow-popup') as HTMLInputElement;
                if (null != followElement) {
                    qq('.btn-close', followElement).forEach(link => {
                        link.addEventListener('click', () => {
                            followElement.classList.add('hide');
                            notify.hide('info-follow-popup-registered');
                        });
                    });
                    qq('a.btn', followElement).forEach(link => {
                        link.addEventListener('click', () => {
                            notify.hide('info-follow-popup-registered');
                        });
                    });
                }
                ga('send', 'event', 'feature-follow', 'onload-popup', '', {
                    nonInteraction: true,
                });
            }
        } else {
            notify.hide('info-follow-popup-registered');
        }
    },
    closeCallback: () => {},
});*/

/**
 * Bookmarks Info Popup
 */
/*
if (null !== q('.tooltip--bookmarks')) {
    notify.addCustom({
        id: 'info-bookmarks-popup',
        priority: 7,
        showMobile: false,
        expires: () => new Date(new Date().getTime() + 3600000 * 24 * 30), //a month
        callback: () => {
            let bookmarks = [];
            try {
                bookmarks = JSON.parse(localStorage.getItem('fi_' + User.id() + '_follows') as string).style;
            } catch (e) {
                //do nothing
            }

            if (bookmarks.length == 0) {
                let followPopupTemplate = require('Project/ts/templates/parts/bookmarks-modal.hbs');
                let container = q('.tooltip--bookmarks') as HTMLElement;
                if (null !== container) {
                    container.insertAdjacentHTML(
                        'beforeend',
                        followPopupTemplate({
                            LANGUAGE_SHORT: LANGUAGE_SHORT,
                        }),
                    );
                    container.classList.add('new');
                    let bookmarkElement = q('#new-function-popup') as HTMLElement;
                    if (null != bookmarkElement) {
                        qq('.btn-close', bookmarkElement).forEach(link => {
                            link.addEventListener('click', () => {
                                container.classList.remove('new');
                                notify.hide('info-bookmarks-popup');
                            });
                        });
                        qq('a.btn', bookmarkElement).forEach(link => {
                            link.addEventListener('click', () => {
                                notify.hide('info-bookmarks-popup');
                            });
                        });
                    }
                    ga('send', 'event', 'feature-bookmarks', 'onload-popup', '', {
                        nonInteraction: true,
                    });
                }
            } else {
                notify.hide('info-bookmarks-popup');
            }
        },
        closeCallback: () => {},
    });
}*/

/**
 * Collections Info Popup
 */

/*let collectionsCount = q('.collections-toggle .badge-error');
let elementCollections = q('#collections');
if (
    null !== elementCollections &&
    !elementCollections.classList.contains('visible') &&
    null !== collectionsCount &&
    collectionsCount.classList.contains('hidden')
) {
    notify.addCustom({
        id: 'info-collections-popup',
        priority: 6,
        showMobile: false,
        expires: () => new Date(new Date().getTime() + 3600000 * 24 * 30), //a month
        callback: () => {
            let collectionsPopupTemplate = require('Project/ts/templates/parts/collections-modal.hbs');
            let container = q('#collections-toggle') as HTMLElement;
            if (null !== container && container.parentNode) {
                let popup = document.createElement('div');
                popup.innerHTML = collectionsPopupTemplate({
                    LANGUAGE_SHORT: LANGUAGE_SHORT,
                    BASE_URL: BASE_URL,
                    URL_STATIC_IMG: URL_STATIC_IMG,
                });
                container.parentNode.insertBefore(popup, container.nextSibling);

                let collectionElement = q('#popup-collections') as HTMLElement;
                if (null != collectionElement) {
                    qq('.popup-close', collectionElement).forEach(link => {
                        link.addEventListener('click', () => {
                            collectionElement.classList.add('hide');
                            notify.hide('info-collections-popup');
                        });
                    });
                    qq('a.btn', collectionElement).forEach(link => {
                        link.addEventListener('click', () => {
                            collectionElement.classList.add('hide');
                            notify.hide('info-collections-popup');
                        });
                    });
                }
                ga('send', 'event', 'feature-collections', 'onload-popup', '', {
                    nonInteraction: true,
                });
            }
        },
        closeCallback: () => {},
    });
}*/
