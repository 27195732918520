import { Arguments } from 'CommonFI/arguments';

export const printSalesforcePixel = () => {
    const pixelParams = ['j', 'sfmc_sub', 'l', 'u', 'jb', 'mid'];
    const pixelUrl = `${BASE_URL}profile/crm/sf_1px.png?`;
    const urlParams = Arguments.stringToObject(window.location.href);
    const urlKeysParams = Object.keys(urlParams);

    if (pixelParams.every(param => urlKeysParams.indexOf(param) !== -1)) {
        const options: string[] = [];
        pixelParams.forEach(param => options.push(`${param}=${urlParams[param]}`));
        new Image().src = `${pixelUrl}${options.join('&')}`;
    }
};
